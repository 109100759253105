/* eslint-disable import/prefer-default-export */
import { config } from './data';

import installFontAwesome from './src/api/installFontAwesome';

import 'prismjs/themes/prism-solarizedlight.css';

const {
  url,
} = config;

installFontAwesome();

const isLocalDevelopment = () => window && window.location && window.location.origin !== url;

if (isLocalDevelopment() === false) {
  console.log('Welcome to online environment.');
}

// Inspired by APlayer
console.log(
  `${'\n'} %c yuming %c http://ymato.cn ${'\n'}${'\n'}`,
  'color: #6cf; background: #030307; padding:5px 0;',
  'background: #6cf; padding:5px 0;',
);

export const onRouteUpdate = (state) => {
  if (isLocalDevelopment() !== true) {
    console.log(`pageview tongji: ${state.location.pathname}`);
  } else {
    console.log('isLocalDevelopment is true, so tongji is not activated');
  }
};
