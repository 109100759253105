import React from 'react';

import ExternalLink from '../ExternalLink';
import { config } from '../../../data';

import './index.scss';

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <p className="copyright">
            Copyright&nbsp;
            <ExternalLink href="http://www.ymato.cn/" title="&copy;ymato" />
            &nbsp;
            {config.title}
            {new Date().getFullYear()}
            &nbsp;&nbsp;&nbsp;
            <ExternalLink href="http://beian.miit.gov.cn" title="鄂ICP备20008937号-1" />
            &nbsp;&nbsp;&nbsp;
            <img src="./assets/beian.png" alt="公网安备" />
            <ExternalLink href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502005490" title="粤公网安备 44030502005490号" />
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
